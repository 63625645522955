<template>
  <div
    class="absolute inset-0 bg-cover bg-center"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <!-- Overlay para mejorar la legibilidad -->
    <div class="absolute inset-0 bg-indigo-900 opacity-50"></div>

    <!-- Atribución de Unsplash -->
    <div class="absolute bottom-2 right-2 text-white text-xs opacity-75 z-20">
      Photo by 
      <a :href="photographerUrl" target="_blank" rel="noopener noreferrer" class="underline">
        {{ photographerName }}
      </a> 
      on 
      <a href="https://unsplash.com?utm_source=Aignis&utm_medium=referral" 
         target="_blank" rel="noopener noreferrer" class="underline">
        Unsplash
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

// Lista de imágenes preseleccionadas de Unsplash
const images = [
  {
    url: '/backgrounds/bg1.jpg',
    photographer: 'Vincent Guth',
    photographerUrl: 'https://unsplash.com/@vingtcent'
  },
  {
    url: '/backgrounds/bg2.jpg',
    photographer: 'Aaron Burden',
    photographerUrl: 'https://unsplash.com/@aaronburden'
  },
  {
    url: '/backgrounds/bg3.jpg',
    photographer: 'Igor Kasalovic',
    photographerUrl: 'https://unsplash.com/@ikasalovic'
  },
  {
    url: '/backgrounds/bg4.jpg',
    photographer: 'Raph Howald',
    photographerUrl: 'https://unsplash.com/@raphhowald'
  },
  {
    url: '/backgrounds/bg5.jpg',
    photographer: 'Magnezis Magnestic',
    photographerUrl: 'https://unsplash.com/@agneska'
  }
];

const backgroundImage = ref(images[0].url); // Imagen predeterminada
const photographerName = ref(images[0].photographer);
const photographerUrl = ref(images[0].photographerUrl);

function selectDailyBackground() {
  const today = new Date().toISOString().split('T')[0]; // Obtener la fecha en formato YYYY-MM-DD
  const cachedDate = localStorage.getItem('backgroundDate');
  const cachedIndex = localStorage.getItem('backgroundIndex');

  if (cachedDate === today && cachedIndex !== null) {
    // Si la fecha coincide con la de hoy, usamos la imagen almacenada
    const selectedImage = images[parseInt(cachedIndex)];
    backgroundImage.value = selectedImage.url;
    photographerName.value = selectedImage.photographer;
    photographerUrl.value = selectedImage.photographerUrl;
  } else {
    // Seleccionar una nueva imagen aleatoria para el día
    const randomIndex = Math.floor(Math.random() * images.length);
    const selectedImage = images[randomIndex];

    backgroundImage.value = selectedImage.url;
    photographerName.value = selectedImage.photographer;
    photographerUrl.value = selectedImage.photographerUrl;

    // Guardar la selección en `localStorage`
    localStorage.setItem('backgroundDate', today);
    localStorage.setItem('backgroundIndex', randomIndex.toString());
  }
}

onMounted(() => {
  selectDailyBackground();
});
</script>
