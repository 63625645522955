<template>
  <div class="min-h-screen bg-cover bg-center flex items-center justify-center p-4 relative">

    <DynamicBackground />

    <div class="w-full max-w-md relative z-10">
      <transition name="fade">
        <div v-if="error"
          class="mb-4 bg-red-100 border-b-4 border-red-500 text-red-700 px-4 py-3 rounded-md shadow-md md:static md:mb-4 fixed top-0 left-0 right-0 z-50"
          role="alert">
          <div class="flex">
            <div class="py-1 w-full pr-8">
              <p class="font-bold">{{ $t('login.error') }}</p>
              <p class="text-sm">{{ error }}</p>
            </div>
          </div>
        </div>
      </transition>

      <div class="bg-white bg-opacity-85 rounded-lg shadow-xl overflow-hidden">
        <!-- Cabecera del formulario -->
        <div class="bg-indigo-600 p-5 text-white">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <img src="@/assets/img/logo.svg" alt="Aignis" class="h-8 w-auto" />
              <h1 class="text-xl font-bold">{{ $t('appName') }}</h1>
            </div>
            <!-- Botones de selección de idioma -->
            <div class="flex space-x-2">
              <button @click="changeLanguage('en')"
                class="text-xs px-2 py-1 bg-indigo-700 text-white rounded hover:bg-indigo-800 transition duration-300">
                EN
              </button>
              <button @click="changeLanguage('es')"
                class="text-xs px-2 py-1 bg-indigo-700 text-white rounded hover:bg-indigo-800 transition duration-300">
                ES
              </button>
            </div>
          </div>
        </div>
        <!-- Formulario de login -->
        <div class="p-6 md:p-8">
          <form @submit.prevent="handleLogin" class="space-y-6">

            <!-- Campo de usuario -->
            <div>
              <label class="block text-sm font-medium text-gray-700 text-left" for="email">{{ $t('login.userName')
                }}</label>
              <input v-model="email" class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                   focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500" id="email"
                type="email" :placeholder="$t('login.userNamePlaceholder')" required />
            </div>

            <!-- Campo de contraseña -->
            <div>
              <label class="block text-sm font-medium text-gray-700 text-left" for="password">{{ $t('login.password')
                }}</label>
              <input v-model="password" class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                   focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500" id="password"
                type="password" :placeholder="$t('login.passwordPlaceholder')" required />
            </div>

            <!-- Recordar sesión -->
            <div class="space-y-4 mt-6 flex justify-center">
              <label class="flex items-center mt-2">
                <input type="checkbox" v-model="rememberMe" id="rememberMe"
                  class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                <span class="ml-2 text-sm text-gray-600">
                  {{ $t('login.remember') }}
                </span>
              </label>
            </div>

            <!-- Botón de iniciar sesión -->
            <div>
              <button
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="submit" :disabled="isLoading">
                <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                {{ isLoading ? $t('login.ctaing') : $t('login.cta') }}
              </button>
            </div>

            <!-- Enlace para recordar contraseña -->
            <div class="text-center">
              <router-link to="/reset-password"
                class="text-sm text-indigo-600 hover:text-indigo-500 transition-colors duration-300">
                {{ $t('login.forgotPassword') }}
              </router-link>
            </div>
          </form>
        </div>

        <!-- Enlace a la página de registro -->
        <div
          class="px-6 md:px-8 py-4 bg-gray-50 border-t border-gray-100 flex flex-col sm:flex-row justify-between items-center">
          <p class="text-xs text-gray-500">{{ $t('login.noAccount') }}</p>
          <a href="/?section=pricing" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">{{
            $t('login.signup') }}</a>
        </div>
      </div>
    </div>

    <!-- PWA Installation Banner -->
    <PwaInstallBanner />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { login } from '../services/api';
import { clearUserInfo, isAuthenticated } from '../utils/auth';
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n';
import PwaInstallBanner from './PwaInstallBanner.vue';
import DynamicBackground from './DynamicBackground.vue';

const { t, locale } = useI18n();

function changeLanguage(lang) {
  locale.value = lang;
  localStorage.setItem('selectedLanguage', lang);
}

const pageTitle = computed(() => t('login.meta.title'));
const pageDescription = computed(() => t('login.meta.description'));

useHead({
  title: pageTitle.value,
  meta: [
    { name: 'description', content: pageDescription.value, },
    { property: 'og:title', content: pageTitle.value, },
    { property: 'og:description', content: pageDescription.value, },
    { name: 'twitter:title', content: pageTitle.value, },
    { name: 'twitter:description', content: pageDescription.value, },
  ],
});

const email = ref('');
const password = ref('');
const rememberMe = ref(true);
const error = ref('');
const isLoading = ref(false);
const router = useRouter();

const handleLogin = async () => {
  isLoading.value = true;
  error.value = '';

  try {
    await login(email.value, password.value, rememberMe.value);
    router.push('/chat');
  } catch (err) {
    console.error(err);
    error.value = err.detail || t('genericError');
    clearUserInfo();
  } finally {
    isLoading.value = false;
  }
};

const clearError = () => {
  error.value = '';
};

onMounted(() => {
  if (isAuthenticated()) {
    router.push('/chat');
  }

  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  });
});
</script>